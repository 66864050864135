.nav {
    width: 100%;
    position: static;
    position: fixed;
    z-index: 1000;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    height: auto;
    background-color: rgba(255, 255, 255);
    top: 0;
    left: 0;
    transition: height 0.3s ease;
}

.navbar.shrink {
    display: flex;
}

.store {
    display: flex;
    align-items: center;
}

.store:hover {
    scale: (1.1);
    text-decoration: underline;
}

.line {
    width: 100%;
    border-bottom: 1px solid rgb(162, 162, 162);
}

.nav-left {
    display: flex;
    align-items: center;
    float: left;
}

.toplogo {
    display: none;
    float: left;
    max-width: 100px;
    max-height: fit-content;
    display: block;
    margin-right: 15px;
}

.nav-righ {
    display: flex;
    align-items: center;
    float: right;
}

.navbar {
    display: flex;
    align-items: center;
    margin: auto;
    width: 100%;
    background-color: rgba(255, 255, 255);
    top: 0;
    left: 0;
}

.nav-items {
    display: flex;
    align-items: center;
    list-style-type: none;
    margin: auto;
    /* padding: 10px; */
    font-family: 'Times New Roman';
}

.nav-list {
    display: inline-block;
    margin-right: 30px;
    justify-content: center;
    /* display: flex; */
    align-items: center;
}

.nav-list a {
    text-decoration: none;
    transition: background-color 0.3 ease;
    padding: 15px;
}

.nav-list:hover {
    color: rgb(58, 41, 21);
    text-decoration: underline;
    border-radius: 30px;
}

/* .search-btn{
    background-color: aliceblue;
    border-radius: 5px;
} */

.categories {
    display: flex;
    float: left;
    text-decoration: none;
    font-size: bolder;
    list-style-type: none;
    margin: auto;
    padding: 10px;
    align-items: center;
}

.categories a {
    margin-left: 9px;
}

.categories:hover {
    background-color: rgb(229, 224, 213);
    border-radius: 30px;
}

.brand {
    margin: auto;
    margin-right: 20px;
    font-family: 'Times New Roman', Times, serif;
    font-size: bolder;
}


.drop-down {
    position: relative;
    background: transparent;
    box-shadow: none;
    z-index: 1000;
}

.dropdown-content {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    padding: 10px;
}

.dropdown-content a {
    font-family: 'Times New Roman';
    text-decoration: none;
}

.hover-element:hover+.dropdown-content {
    display: block;
}

.hover-element {
    cursor: pointer;
}

.menu-icon {
    margin-right: 10px;
    display: flex;
    align-items: center;
    size: 30px;
    display: none;
}

.menutoggle {
    size: 140%;
}

@media only screen and (max-width: 768px) {
    .navbar {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);

    }

    .nav-items {
        border-radius: 10px;
        width: 100%;
        display: flex;
        flex-direction: column;
        margin: auto;
    }

    .nav-list {
        width: 100%;
        border-bottom: 1px solid rgb(204, 204, 204);
    }

    .nav-list:hover {
        transition: transform 0.3s ease;
        transform: scale(1.1);
    }

    .top-logo {
        display: flex;
        justify-content: center;
        width: 150px;
        height: auto;
        margin: auto;
    }

}