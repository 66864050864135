.video {
  position: relative;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: center;
}

.videos {
  display: flex;
  align-items: center;
  justify-content: center;
}

.video-box {
  border-radius: 10px;
  max-height: 650px;

}

.video-head {
  font-family: 'Times New Roman';
  display: inline;
  top: -7px;
  left: 550px;
  margin: auto;
  align-items: center;
  font-size: 150%;
  position: absolute;
  color: rgb(255, 255, 255);
  z-index: 999;
  /* background-color: rgba(255, 255, 255, 0.5);  */
  border-radius: 10px;
  text-align: center;
}

.video-player-container {
  max-width: 600px;
  margin: 0 auto;
}

.video-player {
  width: 100%;
}

.controls-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 4px;
}

.volume-slider {
  width: 100px;
}

.video-style {
  display: flex;
  float: left;
  margin: auto;
  padding-right: 5px;
}

.video-gap{
  padding: 5px;
}

@media screen and (max-width: 600px) {
  .video {
    width: 90%;
    height: auto;
    margin: auto;
  }
}