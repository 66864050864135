.aboutus{
    height: 600px;
    width: 100%;
    background-color: none;
    overflow: hidden;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.about-image {
    display: flex;
    justify-content: space-between;
    border-radius: 30px;
}

.about-image img{

}

.about-text {
    width: 60%;
    padding: 20px;
    /* text-align: left; */
    line-height: 1.5;
    font-family: 'Times New Roman';
    justify-content: center;
    font-size: 1.4em;
}

.abouthead {
    margin-top: 30px;
    padding: 15px;
    font-family: 'Times New Roman';
    color: rgb(0, 0, 0);
    font-size: 180%;
}

img alt {
    color: black;
}

.aboutus img {
    width: 450px;
    height: 450px;
    justify-content: center;
margin: auto;
}

@media  screen  and (max-width: 600px){
    .aboutus{
        width: 100%;
        height: auto;
        margin: auto;
    }

    .about-image{
        display: flex;
        flex-direction: column;
    }

    .about-image img{
        width: 250px;
        height: auto;
        margin: auto;
    }

    .about-text{
        margin: auto;
        font-size: small;
    }
}