.committed{
    height: auto;
    width: 100%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.committed-h1 h1{
    font-family: 'Times New Roman';
    font-size: 180%;
    margin-top: 30px;
    padding: 15px;
}

.containers {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    transition: fliter 0.3s ease-in-out;
    border-radius: 10px;
}

.box {
    height: auto;
    min-height: 150px;
    width: 450px;
    margin: 25px;
    padding: 10px;
    border: 1px solid black;
    /* border-bottom: 1px solid black; */
    font-family: 'Times New Roman';
    color: rgb(104, 74, 29);
    border-radius: 10px;
}

.box h3{
    color: black;
    font-size: large;
    padding-bottom: 5px;
    border-bottom: 1px solid rgb(190, 186, 186);
}

.box p{
    padding: 5px;
}

.box:hover{
    cursor: pointer;
}

@media  screen and (max-width: 600px) {

    .containers{
        display: flex;
        flex-direction: column;
        /* position: absolute; */
        padding: 10px;
        margin: auto;
    }

    .box{
        margin: auto;
        justify-content: center;
        margin: 10px;
    }
}